<template>
    <div class="row my-3">
        <div class="offset-md-2 col-md-4 col-12">
            <label for="time-scale">Tiempo atrás</label>
            <vs-input
                id="time-value"
                v-model="timeValue"
                type="number"
                class="input-theme border-custom"
                name="time-value"
                max="60"
                min="1"
                placeholder="1"
                autocomplete="off"
                @input="changeInput"
            />
        </div>
        <div class="col-md-4 col-12">
            <label for="time-scale">Escala</label>
            <vs-select
                v-model="timeScale"
                :state="stateInputDark"
                placeholder="Escala"
                class="w-100 mw-100 border-custom"
                @input="changeInput"
            >
                <vs-option label="Días" value="agoD">Días</vs-option>
                <vs-option label="Meses" value="agoMon">Mes</vs-option>
            </vs-select>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "TimeAgoFilter",
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        itemsCurrent: {
            type: Object,
            required: false,
            default: () => ({
                timeScale: "agoD",
                timeValue: 1
            })
        }
    },
    data: () => ({
        timeScale: "agoD",
        timeValue: 1
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark", "backgroundColor"])
    },
    watch: {
        itemsCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        if (Object.keys(this.itemsCurrent).length === 0) {
            this.changeInput();
        } else {
            this.setInput(this.itemsCurrent);
        }
    },
    methods: {
        changeInput() {
            this.$emit("change", {
                timeScale: this.timeScale,
                timeValue: this.timeValue
            });
        },
        setInput(value) {
            this.timeScale = String(value.timeScale);
            this.timeValue = Number(value.timeValue);
        }
    }
};
</script>
